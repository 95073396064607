@charset "UTF-8";
/*Color variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
/*Dark Theme*/
/*Dark Theme*/
/**
 * @author zhixin wen <wenzhixin2010@gmail.com>
 */
.ms-offscreen {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: auto !important;
  top: auto !important;
}

.ms-parent {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.ms-choice {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #e1e6f1;
  text-align: left;
  white-space: nowrap;
  line-height: 38px;
  color: #a8afc7;
  text-decoration: none;
  border-radius: 0px;
  background-color: #ffffff;
}
.ms-choice.disabled {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #e1e6f1;
  cursor: default;
}
.ms-choice > span {
  position: absolute;
  top: 0;
  left: 0;
  right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding-left: 8px;
}
.ms-choice > span.placeholder {
  color: #a8afc7;
}
.ms-choice > div.icon-close {
  position: absolute;
  top: 0px;
  right: 16px;
  height: 100%;
  width: 16px;
}
.ms-choice > div.icon-close:before {
  content: "×";
  color: #a8afc7;
  font-weight: bold;
  position: absolute;
  top: 50%;
  margin-top: -14px;
}
.ms-choice > div.icon-close:hover:before {
  color: #333;
}
.ms-choice > div.icon-caret {
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  right: 8px;
  margin-top: -2px;
  border-color: #a8afc7 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
}
.ms-choice > div.icon-caret.open {
  border-color: transparent transparent #a8afc7 transparent;
  border-width: 0 4px 5px 4px;
}

.ms-drop {
  width: auto;
  min-width: 100%;
  overflow: hidden;
  display: none;
  margin-top: -1px;
  padding: 0;
  position: absolute;
  z-index: 1000;
  background: #ffffff;
  color: #334151;
  border: 1px solid #e1e6f1;
  border-radius: 0px;
}
.ms-drop.bottom {
  top: 100%;
}
.ms-drop.top {
  bottom: 100%;
}

.ms-search {
  display: inline-block;
  margin: 0;
  min-height: 26px;
  padding: 2px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  z-index: 10000;
  box-sizing: border-box;
}
.ms-search input {
  width: 100%;
  height: auto !important;
  min-height: 24px;
  padding: 0 5px;
  margin: 0;
  outline: 0;
  font-family: sans-serif;
  border: 1px solid #e1e6f1;
  border-radius: 0px;
  box-shadow: none;
}

.ms-drop ul {
  overflow: auto;
  margin: 0;
  padding: 0;
}
.ms-drop ul > li {
  list-style: none;
  display: list-item;
  background-image: none;
  position: static;
  padding: 0.25rem 8px;
}
.ms-drop ul > li .disabled {
  font-weight: normal !important;
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  cursor: default;
}
.ms-drop ul > li.multiple {
  display: block;
  float: left;
}
.ms-drop ul > li.group {
  clear: both;
}
.ms-drop ul > li.multiple label {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ms-drop ul > li label {
  position: relative;
  padding-left: 1.25rem;
  margin-bottom: 0;
  font-weight: normal;
  display: block;
  white-space: nowrap;
  cursor: pointer;
}
.ms-drop ul > li label.optgroup {
  font-weight: bold;
}
.ms-drop ul > li.hide-radio {
  padding: 0;
}
.ms-drop ul > li.hide-radio:focus, .ms-drop ul > li.hide-radio:hover {
  background-color: #f8f9fa;
}
.ms-drop ul > li.hide-radio.selected {
  color: #ffffff;
  background-color: #007bff;
}
.ms-drop ul > li.hide-radio label {
  margin-bottom: 0;
  padding: 5px 8px;
}
.ms-drop ul > li.hide-radio input {
  display: none;
}
.ms-drop ul > li.option-level-1 label {
  padding-left: 28px;
}
.ms-drop input[type=radio], .ms-drop input[type=checkbox] {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.ms-drop .ms-no-results {
  display: none;
}/*# sourceMappingURL=multiple-select.css.map */